<template>
  <div class="row mt-60">
    <loader-component v-if="loading" />
    <div class="col-md-9">
      <ul class="new-service-list">
        <li>
          <a
            href="javascript:void(0)"
            :class="filter.center == '' ? 'active' : ''"
            @click="
              filter.center = '';
              showCenterFilter = false;
              getReports();
            "
            >All</a
          >
        </li>
        <li v-for="(item, key) in centers" :key="key">
          <a
            href="javascript:void(0)"
            :class="item.short_form == filter.center ? 'active' : ''"
            @click="
              filter.center = item.short_form;
              showCenterFilter = false;
              getReports();
            "
            >{{ item.short_form }}</a
          >
        </li>
        <!-- <li><a href="javascript:void(0)" :class="filter.patientType == 'booked_appointment'?'active':''" @click="filter.patientType = 'booked_appointment'; getReports()">Consultation</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Zero Clubfoot'?'active':''" @click="filter.patientType = 'Zero Clubfoot'; getReports()">Zero Clubfoot</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Physiotherapy'?'active':''" @click="filter.patientType = 'Physiotherapy'; getReports()">Physiotherapy</a></li>
        <li><a href="javascript:void(0)" :class="filter.patientType == 'Dental'?'active':''" @click="filter.patientType = 'Dental'; getReports()">Dental Care</a></li> -->
      </ul>
    </div>
    <div class="col-md-3">
      <ul class="filter-list-new">
        <li>
          <b-button
            class="coll-btn"
            id="popover-month"
            @click="selectFilter('Month')"
            >{{
              filter.quarter
                ? "Q" + filter.quarter
                : filter.month
                ? filter.month
                : "Month"
            }}
            <i class="fas fa-chevron-down"></i
          ></b-button>
          <b-popover
            target="popover-month"
            placement="bottomleft"
            :show="showMonthFilter"
          >
            <ul class="month-selection-list" style="width: 340px;">
              <li v-for="(item, key) in months" :key="key">
                <a
                  v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)"
                  href="javascript:void(0)"
                  :class="item.text == filter.month ? 'active' : ''"
                  @click="
                    selectPeriod('monthly', item.text);
                    showMonthFilter = false;
                    getReports();
                  "
                  >{{ item.text }}</a
                >
                <a
                  v-else
                  href="javascript:void(0)"
                  :class="item.value == filter.quarter ? 'active' : ''"
                  @click="
                    selectPeriod('quarterly', item.value);
                    showMonthFilter = false;
                    getReports();
                  "
                  >{{ item.text }}</a
                >
              </li>
            </ul>
          </b-popover>
        </li>

        <li>
          <b-button
            class="coll-btn"
            id="popover-year"
            @click="selectFilter('Year')"
            >{{ filter.year }} <i class="fas fa-chevron-down"></i
          ></b-button>
          <b-popover
            target="popover-year"
            placement="bottomleft"
            :show="showYearFilter"
          >
            <ul class="year-selection-list" style="width: 280px;">
              <li>
                <a
                  href="javascript:void(0)"
                  :class="filter.year == year ? 'active' : ''"
                  @click="
                    filter.year = year;
                    getReports();
                  "
                  v-for="(year, index) in years"
                  :key="index"
                  >{{ year }}</a
                >
              </li>
            </ul>
          </b-popover>
        </li>
      </ul>
    </div>

    <div class="col-md-5">
      <div class="c-card">
        <div class="filter-section">
          <form>
            <!-- <ul class="new-center-list">
              <li>
                  <a href="javascript:void(0)" :class="filter.center==''?'active':''" @click="filter.center = ''; showCenterFilter=false; getReports()">All</a>
              </li>
              <li v-for="(item, key) in centers" :key="key">
                <a href="javascript:void(0)" :class="item.short_form == filter.center?'active':''" @click="filter.center = item.short_form; showCenterFilter=false; getReports()">{{ item.short_form }}</a>
              </li>
            </ul> -->

            <ul class="tab-list">
              <li
                @click="activeTab = 'patient'"
                :class="{ active: activeTab == 'patient' }"
              >
                Patient
              </li>
              <li
                @click="activeTab = 'income'"
                :class="{ active: activeTab == 'income' }"
              >
                Income
              </li>
              <li>Expense</li>
            </ul>

            <div class="c-tab-content" v-if="activeTab == 'patient'">
              <ul class="service-list">
                <li>
                  <h3 class="title">Zero Clubfoot</h3>
                  <div class="service-content">
                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>New Patients</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('zcf','treatment', 'patient_count') }}<small> / {{ getTarget('zcf', 'Treatment', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('zcf', 'Treatment', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('zcf','treatment', 'patient_count') *100)/getTarget('zcf', 'Treatment', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('zcf','treatment', 'patient_count') *100)/getTarget('zcf', 'Treatment', 'total_target'))}"
                        ></div>
                      </div>
                    </div>

                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Follow-ups</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('zcf','followup', 'patient_count') }}<small> / {{ getTarget('zcf', 'Followup', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('zcf', 'Followup', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('zcf','followup', 'patient_count') *100)/getTarget('zcf', 'Followup', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('zcf','followup', 'patient_count') *100)/getTarget('zcf', 'Followup', 'total_target'))}"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="getServiceCount('zcf','treatment', 'patient_count')">
                    <div class="ref">Ref:</div>
                    <ul
                      class="c-progressbar"
                      v-if="services['zcf'] && services['zcf']['reg_sources']"
                    >
                      <li
                        v-for="(item, key) in services['zcf']['reg_sources']"
                        :key="key"
                        :title="
                          `${getRegLabel(item.platform)} - ${item.patient_count}`
                        "
                        v-if="item.patient_count"
                        :style="{
                          width: getServiceCount('zcf','treatment', 'patient_count')
                            ? (item.patient_count *
                                100) /
                                getServiceCount('zcf','treatment', 'patient_count') +
                              '%'
                            : 0 + '%',
                          'background-color': getRegColor(item.platform),
                        }"
                      >
                      
                      </li>
                    </ul>

                    <div class="progress_bar_bullet">
                          <p v-for="(item, key) in regSources" :key="key" v-if="getRegCountBySource('zcf', item.name)">
                            <span :style="{'background-color': item.color}"></span>{{ item.label }} ({{ getRegCountBySource('zcf', item.name) }})
                          </p>
                      </div>
                  </div>
                </li>


                <li>
                  <h3 class="title">Physiotherapy</h3>
                  <div class="service-content">
                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>New Patients</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('pht','treatment', 'patient_count') }}<small> / {{ getTarget('pht', 'Treatment', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('pht', 'Treatment', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('pht','treatment', 'patient_count') *100)/getTarget('pht', 'Treatment', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('pht','treatment', 'patient_count') *100)/getTarget('pht', 'Treatment', 'total_target'))}"
                        ></div>
                      </div>
                    </div>

                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Follow-ups</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('pht','followup', 'patient_count') }}<small> / {{ getTarget('pht', 'Followup', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                        v-if="getTarget('pht', 'Followup', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('pht','followup', 'patient_count') *100)/getTarget('pht', 'Followup', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('pht','followup', 'patient_count') *100)/getTarget('pht', 'Followup', 'total_target'))}"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="getServiceCount('pht','treatment', 'patient_count')">
                    <div class="ref">Ref:</div>
                      <ul
                        class="c-progressbar"
                        v-if="services['pht'] && services['pht']['reg_sources']"
                      >
                        <li
                          v-for="(item, key) in services['pht']['reg_sources']"
                          :key="key"
                          :title="
                            `${getRegLabel(item.platform)} - ${item.patient_count}`
                          "
                          v-if="item.patient_count"
                          :style="{
                            width: getServiceCount('pht','treatment', 'patient_count')
                              ? (item.patient_count *
                                  100) /
                                  getServiceCount('pht','treatment', 'patient_count') +
                                '%'
                              : 0 + '%',
                            'background-color': getRegColor(item.platform),
                          }"
                        >
                        
                        </li>
                      </ul>

                      <div class="progress_bar_bullet">
                            <p v-for="(item, key) in regSources" :key="key" v-if="getRegCountBySource('pht', item.name)">
                              <span :style="{'background-color': item.color}"></span>{{ item.label }} ({{ getRegCountBySource('pht', item.name) }})
                            </p>
                        </div>
                  </div>
                </li>


                <li>
                  <h3 class="title">Dental Care</h3>
                  <div class="service-content">
                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>New Patients</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('dental','treatment', 'patient_count') }}<small> / {{ getTarget('dental', 'Treatment', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('dental', 'Treatment', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('dental','treatment', 'patient_count') *100)/getTarget('dental', 'Treatment', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('dental','treatment', 'patient_count') *100)/getTarget('dental', 'Treatment', 'total_target'))}"
                        ></div>
                      </div>
                    </div>

                    <div class="partition">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Follow-ups</span> <span>/ Target</span>
                            <h3>{{ getServiceCount('dental','followup', 'patient_count') }}<small> / {{ getTarget('dental', 'Followup', 'total_target') }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                        v-if="getTarget('dental', 'Followup', 'total_target')"
                          role="progressbar"
                          :aria-valuenow="Math.round((getServiceCount('dental','followup', 'patient_count') *100)/getTarget('dental', 'Followup', 'total_target'))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round((getServiceCount('dental','followup', 'patient_count') *100)/getTarget('dental', 'Followup', 'total_target'))}"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="getServiceCount('dental','treatment', 'patient_count')">
                    <div class="ref">Ref:</div>
                      <ul
                        class="c-progressbar"
                        v-if="services['dental'] && services['dental']['reg_sources']"
                      >
                        <li
                          v-for="(item, key) in services['dental']['reg_sources']"
                          :key="key"
                          :title="
                            `${getRegLabel(item.platform)} - ${item.patient_count}`
                          "
                          v-if="item.patient_count"
                          :style="{
                            width: getServiceCount('dental','treatment', 'patient_count')
                              ? (item.patient_count *
                                  100) /
                                  getServiceCount('dental','treatment', 'patient_count') +
                                '%'
                              : 0 + '%',
                            'background-color': getRegColor(item.platform),
                          }"
                        >
                        
                        </li>
                      </ul>

                      <div class="progress_bar_bullet">
                            <p v-for="(item, key) in regSources" :key="key" v-if="getRegCountBySource('dental', item.name)">
                              <span :style="{'background-color': item.color}"></span>{{ item.label }} ({{ getRegCountBySource('dental', item.name) }})
                            </p>
                      </div>
                  </div>
                </li>
              </ul>

            </div>

            <div class="c-tab-content" v-if="activeTab == 'income'">
              <ul class="service-list">
                <li>
                  <h3 class="title">Zero Clubfoot</h3>
                  <div class="service-content">
                    <div class="partition w-75">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Income (Tk)</span> <span>/ Target</span>
                            <h3 class="font-36">{{ numberWithCommas(getServiceCount('zcf','treatment', 'income') + getServiceCount('zcf','followup', 'income')) }}<small> / {{ numDifferentiation(getTarget('zcf', 'Treatment', 'total_income') + getTarget('zcf', 'Followup', 'total_income')) }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('zcf', 'Treatment', 'total_income')"
                          role="progressbar"
                          :aria-valuenow="Math.round(((getServiceCount('zcf','treatment', 'income') + getServiceCount('zcf','followup', 'income')) *100)/(getTarget('zcf', 'Treatment', 'total_income') + getTarget('zcf', 'Followup', 'total_income')))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round(((getServiceCount('zcf','treatment', 'income') + getServiceCount('zcf','followup', 'income')) *100)/(getTarget('zcf', 'Treatment', 'total_income') + getTarget('zcf', 'Followup', 'total_income')))}"
                          class="income"
                        ></div>
                      </div>
                    </div>

                    <div class="partition w-25">
                      <ul class="income-target-list">
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas(getServiceCount('zcf','treatment', 'income')) }}</span>
                            <p>TP Income</p>
                          </li>
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas(getServiceCount('zcf','followup', 'income')) }}</span>
                            <p>FU Income</p>
                          </li>
                        </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <h3 class="title">Physiotherapy</h3>
                  <div class="service-content">
                    <div class="partition w-75">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Income (Tk)</span> <span>/ Target</span>
                            <h3 class="font-36">{{ numberWithCommas(getServiceCount('pht','treatment', 'income') + getServiceCount('pht','followup', 'income')) }}<small> / {{ numDifferentiation(getTarget('pht', 'Treatment', 'total_income') + getTarget('pht', 'Followup', 'total_income')) }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('pht', 'Treatment', 'total_income')"
                          role="progressbar"
                          :aria-valuenow="Math.round(((getServiceCount('pht','treatment', 'income') + getServiceCount('pht','followup', 'income')) *100)/(getTarget('pht', 'Treatment', 'total_income') + getTarget('pht', 'Followup', 'total_income')))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round(((getServiceCount('pht','treatment', 'income') + getServiceCount('pht','followup', 'income')) *100)/(getTarget('pht', 'Treatment', 'total_income') + getTarget('pht', 'Followup', 'total_income')))}"
                          class="income"
                        ></div>
                      </div>
                    </div>

                    <div class="partition w-25">
                      <ul class="income-target-list">
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas(getServiceCount('pht','treatment', 'income')) }}</span>
                            <p>TP Income</p>
                          </li>
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas(getServiceCount('pht','followup', 'income')) }}</span>
                            <p>FU Income</p>
                          </li>
                        </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <h3 class="title">Dental Care </h3>
                  <div class="service-content">
                    <div class="partition w-75">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Orko's Income (Tk)</span> <span>/ Target</span>
                            <h3 class="font-36">{{ numberWithCommas((getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income'))*0.15)}}<small> / {{ numDifferentiation(getTarget('dental', 'Treatment', 'total_income') + getTarget('dental', 'Followup', 'total_income')) }}</small></h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        <div
                          v-if="getTarget('dental', 'Treatment', 'total_income')"
                          role="progressbar"
                          :aria-valuenow="Math.round(((getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income')) * 0.15 *100)/(getTarget('dental', 'Treatment', 'total_income') + getTarget('dental', 'Followup', 'total_income')))"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{'--value': Math.round(((getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income')) * 0.15 *100)/(getTarget('dental', 'Treatment', 'total_income') + getTarget('dental', 'Followup', 'total_income')))}"
                          class="income"
                        ></div>
                      </div>
                    </div>

                    <div class="partition w-25">
                      <ul class="income-target-list">
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas(getServiceCount('dental','treatment', 'income')) }}</span>
                            <p>TP Income</p>
                          </li>
                          <!-- <li>
                            <span>{{ getServiceCount('dental','followup', 'income') }}</span>
                            <p>FU Income</p>
                          </li> -->
                          <li>
                            <span><small style="font-weight: 600;">Tk.</small> {{ numberWithCommas((getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income'))*0.85) }}</span>
                            <p>Dentist's Income</p>
                          </li>
                        </ul>
                    </div>
                  </div>

                  <!-- <div class="service-content">
                    <div class="partition w-75">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Dentist's Income (Tk)</span> <span></span>
                            <h3 class="font-36">{{ (getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income'))*0.85 }}</h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                        
                      </div>
                    </div>

                    <div class="partition w-25">
                      
                    </div>
                  </div> -->
                </li>

                <li>
                  <h3 class="title">Consultation</h3>
                  <div class="service-content">
                    <div class="partition w-75">
                      <div class="lft">
                        <ul>
                          <li>
                            <span>Income (Tk)</span> <span>&nbsp;</span>
                            <h3 class="font-36">{{ numberWithCommas(getServiceCount('consultation','treatment', 'income')) }}</h3>
                          </li>
                        </ul>
                      </div>
                      <div class="rht">
                      </div>
                    </div>

                    <div class="partition w-25">
                      
                    </div>
                  </div>
                </li>

                <li>
                  <h3 class="title" style="color: #3DB;">Orko's Total Income</h3>
                  <div class="service-content">
                    <div class="lft">
                      <h3 style="color: orange; font-size: 36px; font-weight: bold;">
                        <small style="font-weight: bold;">Tk. </small>
                      {{ numberWithCommas(Math.round(getServiceCount('consultation','treatment', 'income') + (getServiceCount('dental','treatment', 'income') + getServiceCount('dental','followup', 'income'))*0.15) + 
                        getServiceCount('pht','treatment', 'income') + getServiceCount('pht','followup', 'income') + getServiceCount('zcf','treatment', 'income') + getServiceCount('zcf','followup', 'income')) }}</h3>
                      </div>
                      <div class="rht">
                        
                      </div>
                  </div>
                </li>

              </ul>
            </div>
          </form>

          <!-- <b-dropdown id="dropdown-1" :text="month" class="m-md-2 w-100">
            <b-row>
              <b-col cols="4">
                <div>
                  <b-button pill class="my-2" size="sm">Q1</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q2</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q3</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q4</b-button>
                </div>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                      month.text
                    }}
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-dropdown>
          <form class="text-center">
            <b-tabs content-class="mt-3" align="center">
              <b-tab :title="month">
                <b-row>
                  <b-col cols="3">
                    <b-button pill class="my-2" size="sm">Q1</b-button>
                    <b-button pill class="my-2" size="sm">Q2</b-button>
                    <b-button pill class="my-2" size="sm">Q3</b-button>
                    <b-button pill class="my-2" size="sm">Q4</b-button>

                  </b-col>
                  <b-col cols="9">
                    <div>
                      <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                          month.text
                        }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="All Center">
                <b-button pill class="my-2 mx-5" size="sm">CTG</b-button>
                <b-button pill class="my-2 mx-5" size="sm">CM</b-button>
                <b-button pill class="my-2 mx-5" size="sm">BB</b-button>
              </b-tab>
              <b-tab title="Zero Clubfoot">
                <b-button pill class="my-2 mx-5" size="sm">Zero Clubfoot</b-button>
                <b-button pill class="my-2 mx-5" size="sm">Physiotherapy</b-button>
              </b-tab>
            </b-tabs>
          </form> -->
        </div>
        <!-- <div class="target-content">
          <h2 class="content-title">Treatment Phase (TP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'Plaster Material',
                  'therapy_session', 'therapy_appointment', 'assistive_device', 'Dental', 'dental_appointment'].includes(service.product_type)
                  && (checkTargetExists(service.product_type) || filter.patientType == 'Plaster Material')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ getServiceCount(service.product_type, service.patient_count) }}</span> /
                  <span class="target">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar :value="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>

          <h2 class="content-title">Follow-up Phase (FP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['clubfoot_appointment', 'brace', 'therapy_session_fp', 'therapy_appointment_fp', 'assistive_device_fp', 'Dental_fp', 'dental_appointment_fp'].includes(service.product_type) && (checkTargetExists(service.product_type) || filter.patientType == 'Dental' || filter.patientType == 'Physiotherapy')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ service.patient_count }}</span> <span v-if="getTarget(service.product_type)">/</span>
                  <span class="target" v-if="getTarget(service.product_type)">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(service.patient_count, getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                <b-progress-bar :value="calculateProgress(service.patient_count, getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(service.patient_count, getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>

    <div class="col-md-7">
      <div class="c-card p-7">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
              <tr>
                <th>Timeline</th>
                <th>Service</th>
                <th>New Patient</th>
                <th>FU Patient</th>
                <th>No of service</th>
                <th>Income (Tk.)</th>
              </tr>
            </thead>

            <tbody
              v-for="(data, index) in table_data"
              :key="index"
              :class="data.expand ? 'expanded-row' : ''"
            >
              <tr v-if="!data.expand">
                <td
                  @click="handleRowClicked(index, data.date)"
                  style="cursor:pointer;"
                >
                  {{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}
                </td>
                <td>{{ data.label }}</td>
                <td>{{ data.new_patient }}</td>
                <td>{{ data.fu_patient }}</td>
                <td>{{ data.patient_count }}</td>
                <td>{{ numberFormat(data.total_income) }}</td>
              </tr>

              <tr
                v-if="data.expand && data.services && data.services.length"
                v-for="(service, sIndex) in data.services"
                :key="sIndex"
              >
                <td
                  :rowspan="data.services.length"
                  v-if="sIndex == 0"
                  style="vertical-align: top !important; cursor:pointer;"
                  @click="handleRowClicked(index, data.date)"
                >
                  <strong>{{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}</strong>
                </td>
                <td>{{ service.product_type }}</td>
                <td>
                  {{ service.new_patient }}
                </td>
                <td>
                  {{ service.fu_patient }}
                </td>
                <td>
                  {{ service.patient_count }}
                </td>
                <td>{{ numberFormat(service.total_income) }}</td>
              </tr>
              <tr
                v-if="data.expand && data.services && data.services.length"
                class="expanded-total-row"
              >
                <td>
                  <ul>
                    <li>
                      <strong>Total</strong>
                    </li>
                    <li>
                      {{
                        filter.period == "monthly" ? "Yesterday" : "Last Month"
                      }}
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      <strong>{{ data.new_patient }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.new_patient,
                            data.prev_day.new_patient
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.new_patient,
                              data.prev_day.new_patient
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.new_patient,
                            data.prev_day.new_patient
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <strong>{{ data.fu_patient }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.fu_patient,
                            data.prev_day.fu_patient
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.fu_patient,
                              data.prev_day.fu_patient
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.fu_patient,
                            data.prev_day.fu_patient
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <strong>{{ data.patient_count }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.patient_count,
                            data.prev_day.patient_count
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.patient_count,
                              data.prev_day.patient_count
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.patient_count,
                            data.prev_day.patient_count
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      <strong>{{ numberFormat(data.total_income) }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.total_income,
                            data.prev_day.total_income
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.total_income,
                              data.prev_day.total_income
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.total_income,
                            data.prev_day.total_income
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>

              <tr v-if="data.expand && data.services && !data.services.length">
                <td>
                  <strong>{{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}</strong>
                </td>
                <td colspan="4">
                  <strong>Data not available</strong>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="summary-wrapper">
          <table class="summary-tbl">
            <thead>
              <tr>
                <th style="width: 26%;">
                  {{
                    filter.period == "monthly"
                      ? filter.month + " " + filter.year
                      : "Q" + filter.quarter + " " + filter.year
                  }}
                </th>
                <th style="width: 19%; text-align: center;">
                  {{ incomeCalculate("new_patient") }}
                </th>
                <th style="width: 18%; text-align: center;">
                  {{ incomeCalculate("fu_patient") }}
                </th>
                <th style="width: 18%; text-align: center;">
                  {{ incomeCalculate("patient_count") }}
                </th>
                <th style="width: 18%; text-align: right;">
                  {{ numberFormat(incomeCalculate("total_income")) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 26%;">
                  {{
                    filter.period == "monthly" ? "Last Month" : "Last Quarter"
                  }}
                </td>
                <td style="width: 19%; text-align: center;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('new_patient'),
                        lastMonthData.new_patient
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('new_patient'),
                          lastMonthData.new_patient
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("new_patient"),
                        lastMonthData.new_patient
                      )
                    }}%</span
                  >
                </td>
                <td style="width: 18%; text-align: center;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('fu_patient'),
                        lastMonthData.fu_patient
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('fu_patient'),
                          lastMonthData.fu_patient
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("fu_patient"),
                        lastMonthData.fu_patient
                      )
                    }}%</span
                  >
                </td>
                <td style="width: 18%; text-align: center;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('patient_count'),
                        lastMonthData.patient_count
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('patient_count'),
                          lastMonthData.patient_count
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("patient_count"),
                        lastMonthData.patient_count
                      )
                    }}%</span
                  >
                </td>
                <td style="width: 18%; text-align: right;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('total_income'),
                        lastMonthData.total_income
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('total_income'),
                          lastMonthData.total_income
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("total_income"),
                        lastMonthData.total_income
                      )
                    }}%</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "OCCReportView",
  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1",
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2",
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3",
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4",
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      regSources: [
        {
          name: 'Poster',
          label: 'PM',
          color: '#FCD603'
        },
        {
          name: 'Social',
          label: 'DM',
          color: '#FAA919'
        },
        {
          name: 'Doctor Referral',
          label: 'Dr',
          color: '#F36F25'
        },
        {
          name: 'Patient Referral',
          label: 'PT',
          color: '#3EB3E6'
        },
        {
          name: 'register',
          label: 'App',
          color: '#66F757'
        },
        {
          name: 'Employee Referral',
          label: 'EM',
          color: '#cae342'
        },
        {
          name: 'NGO',
          label: 'NGO',
          color: '#cae'
        },
        {
          name: 'Others',
          label: 'Others',
          color: '#f36'
        },
      ],
      loading: false,
      activeTab: "patient",
      value: 88,
      max: 100,
      months: all_months,
      filter: {
        period: "monthly",
        month: "",
        center: "",
        patientType: "Zero Clubfoot",
        year: new Date().getFullYear(),
        quarter: "",
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      table_data: [],
      services: [],
      targets: [],
      reg_sources: [],
      lastMonthData: {},
      showMonthFilter: false,
      showYearFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      centers: [],
    };
  },
  created() {
    this.filter.month = moment().format("MMM");
    this.setYears();
    this.getReports();
    this.centerList();
    localStorage.setItem("tabIndex", 4);
    this.$root.$emit("call-active-menu", 4);
  },
  methods: {
    numberWithCommas(x) {
      if(!x) return 0
      const num = Number(x).toFixed(1);
      return num.toString().split('.')[0].length > 3 ? num.toString().substring(0,num.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + num.toString().substring(num.toString().split('.')[0].length-3): num.toString();
    },
    numDifferentiation(val) {
      if (val >= 10000000)
        val = Math.round((val / 10000000) * 100) / 100 + " C";
      else if (val >= 100000)
        val = Math.round((val / 100000) * 100) / 100 + " L";
      else if (val >= 1000) val = Math.round((val / 1000) * 100) / 100 + " K";
      return val;
    },
    selectPeriod(type, value) {
      this.filter.period = type;
      if (type == "monthly") {
        this.filter.quarter = "";
        this.filter.month = value;
      } else {
        this.filter.month = "";
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
      return year ? String(year).substring(2, 4) : "";
    },
    setYears() {
      let cYear = new Date().getFullYear();
      while (this.start_year <= cYear) {
        this.years.push(this.start_year++);
      }
    },
    numberFormat(num) {
      return num ? (Math.round(num * 100) / 100).toFixed(2) : 0;
    },
    calculateProgress(achieved, target) {
      if (target > 0) {
        return Math.floor((achieved * 100) / target);
      }
      return 0;
    },
    checkTargetExists(product) {
      return this.targets.some((item) => item.product_type == product);
    },
    getTarget(serviceType, product, fieldName) {
      if(!this.targets[serviceType]) return 0
      
      let target = this.targets[serviceType].find((item) => item.product_type == product);
      if (target) {
        return target[fieldName];
      }
      return 0;
    },
    getServiceCount(serviceType, phase, fieldName) {
      if (!this.services[serviceType] || !this.services[serviceType][phase]) {
        return 0;
      }
      return this.services[serviceType][phase][fieldName]
        ? this.services[serviceType][phase][fieldName]
        : 0;
    },
    getRegCountBySource(serviceType,platform){
      if (!this.services[serviceType]) {
        return 0;
      }
      let regSource = this.services[serviceType]['reg_sources'].find((item) => item.platform == platform);
      if(regSource){
        return regSource.patient_count
      }else{
        return 0;
      }
    },
    getRegColor(platform){
      let regSource = this.regSources.find((item) => item.name == platform);
      if(regSource){
        return regSource.color
      }else{
        return '#ccd';
      }
    },
    getRegLabel(platform){
      let regSource = this.regSources.find((item) => item.name == platform);
      if(regSource){
        return regSource.label
      }else{
        return 'Not Matched';
      }
    },
    handleRowClicked(index, date) {
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(
          (item) => item.short_form == this.filter.center
        );
      }

      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/report/orkocenter/new/occ/center-detail/by-date?type=center&api_token=${localStorage.getItem(
            "api_token"
          )}${selectedCenter ? "&institute_id=" + selectedCenter.id : ""}
              &date=${date}&period=${this.filter.period}`
        )
        .then((res) => {
          this.table_data = this.table_data.map((item, key) => {
            if (index == key) {
              item.expand = !item.expand;
              item.services = res.data.data.filter((product) => {
                if (product.total_income || product.patient_count) {
                  return product;
                }
              });
              item.prev_day = res.data.prev_day;
            } else {
              item.expand = false;
            }
            return item;
          });

          this.loading = false;
        });
    },
    calculatePercentage(currentMonth, prevMonth) {
      let diff = currentMonth - prevMonth;
      if (diff != 0 && prevMonth != 0) {
        return Math.round((diff / prevMonth) * 100 * 100) / 100;
        // return Math.floor(diff / prevMonth * 100);
      } else if (diff != 0 && prevMonth == 0) {
        return Math.round(diff * 100) / 100;
        // return Math.floor(diff);
      } else {
        return 0;
      }
    },
    incomeCalculate(type) {
      let total = 0;
      this.table_data.forEach((item) => {
        total += item[type];
      });
      return total;
    },
    dateFormat(date) {
      if (this.filter.period == "monthly") {
        return date ? moment(date).format("D MMM") : "";
      } else {
        return date ? moment(date).format("MMM YYYY") : "";
      }
    },
    labelUpdate(label) {
      if (label == "Ponseti (Plaster)") {
        return "Casting";
      } else if (label == "Ponseti (Surgery)") {
        return "Surgery";
      } else if (label == "Ponseti (Tenotomy)") {
        return "Tenotomy";
      } else if (label == "registration_fee") {
        return "Registration";
      } else if (label == "brace") {
        return "Brace";
      } else if (label == "appointment") {
        return "Consultation";
      } else if (label == "therapy_appointment") {
        return "Consultation";
      } else if (label == "therapy_appointment_fp") {
        return "Follow-up Consultation";
      } else if (label == "clubfoot_appointment") {
        return "Consultation";
      } else if (label == "clubfoot_appointment_fp") {
        return "Follow-up Consultation";
      } else if (label == "dental_appointment") {
        return "Consultation";
      } else if (label == "dental_appointment_fp") {
        return "Follow-up Consultation";
      } else if (label == "Dental") {
        return "Procedure";
      } else if (label == "Dental_fp") {
        return "Follow-up Procedure";
      } else if (label == "therapy_session") {
        return "Session";
      } else if (label == "therapy_session_fp") {
        return "Follow-up Session";
      } else if (label == "assistive_device") {
        return "Assistive Device";
      } else if (label == "assistive_device_fp") {
        return "Assistive Device";
      } else if (label == "Plaster Material") {
        return "Casting Material";
      } else if (label == "Plaster Material_fp") {
        return "Follow-up Casting Material";
      } else if (label == "Ponseti (Plaster)_fp") {
        return "Follow-up Casting";
      } else if (label == "Ponseti (Surgery)_fp") {
        return "Follow-up Surgery";
      } else if (label == "Ponseti (Tenotomy)_fp") {
        return "Follow-up Tenotomy";
      } else if (label == "brace_fp") {
        return "Follow-up Brace";
      } else if (label == "booked_appointment") {
        return "Consultation";
      } else if (label == "booked_appointment_fp") {
        return "Follow-up Consultation";
      } else {
        return "Label not matched";
      }
    },
    selectFilter(type) {
      if (type == "Month") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showYearFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Year") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showMonthFilter = false;
        this.showYearFilter = !this.showYearFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showPatientFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      } else if (type == "Patient") {
        this.showMonthFilter = false;
        this.showCenterFilter = false;
        this.showPatientFilter = !this.showPatientFilter;
      }
    },
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(
          (item) => item.short_form == this.filter.center
        );
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/report/orkocenter/new/occ/center?api_token=${localStorage.getItem(
            "api_token"
          )}${this.filter.month ? "&month=" + this.filter.month : ""}${
            selectedCenter ? "&institute_id=" + selectedCenter.id : ""
          }
              &period=${this.filter.period}&year=${this.filter.year}${
            this.filter.quarter ? "&quarter=" + this.filter.quarter : ""
          }`
        )
        .then((res) => {
          this.table_data = res.data.data.data.reverse();
          this.table_data = this.table_data.map((item) => {
            item.prev_day = {};
            item.services = [];
            if (moment(item.date).isSame(moment(), "day")) {
              item.expand = false; //TODO
              item.isToday = true;
            } else {
              item.expand = false;
              item.isToday = false;
            }
            return item;
          });

          this.services = res.data.service_counts;
          this.lastMonthData = res.data.last_month;
          this.targets = res.data.target_counts;
          this.loading = false;

          // let servArr = [];
          // if(this.filter.patientType == 'Zero Clubfoot') {
          //   servArr = ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'brace', 'clubfoot_appointment'];
          // } else if (this.filter.patientType == 'Dental') {
          //   servArr = ['Dental', 'dental_appointment', 'Dental_fp', 'dental_appointment_fp'];
          // } else if (this.filter.patientType == 'Plaster Material') {
          //   servArr = ['Plaster Material'];
          // } else {
          //   servArr = ['therapy_appointment', 'therapy_session', 'assistive_device', 'therapy_appointment_fp', 'therapy_session_fp', 'assistive_device_fp'];
          // }

          // servArr.forEach(serv => {
          //   if(!this.services.some(item => item.product_type == serv)) {
          //     this.services.push({ product_type: serv, patient_count: 0, total_income: 0 })
          //   }
          // });

          // this.services = this.sortService(this.services, ['registration_fee', 'Ponseti (Plaster)', 'Ponseti (Tenotomy)', 'Ponseti (Surgery)', 'clubfoot_appointment', 'brace', 'therapy_appointment', 'Plaster Material', 'therapy_appointment_fp', 'therapy_session','therapy_session_fp', 'assistive_device', 'dental_appointment', 'dental_appointment_fp'])
        });
    },
    sortService(services, orders) {
      let newArr = [];
      for (let i = 0; i < orders.length; i++) {
        if (services.some((e) => e.product_type === orders[i])) {
          newArr.push(services.find((e) => e.product_type === orders[i]));
        }
      }

      for (let k = 0; k < services.length; k++) {
        if (!newArr.some((e) => e.product_type === services[k].product_type)) {
          newArr.push(
            services.find((e) => e.product_type === services[k].product_type)
          );
        }
      }
      return newArr;
    },
    centerList() {
      axios
        .get(
          `${
            process.env.VUE_APP_URL
          }/api/v1/institute/list?type=orko&api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            // this.loading = false
            this.centers = response.data.data;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>

@property --pgPercentage {
            syntax: "<number>";
            inherits: true;
            initial-value: 0;
          }
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 33.333333%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #aaa;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #aaa;
          td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
        td {
          text-align: center;
          font-size: 16px;
          line-height: 2.5;
          color: #002170;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;

  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.new-service-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  // margin: 0;
  li {
    width: 85px;
    float: left;
    text-align: center;
    a {
      background-color: #fff;
      width: 92%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #3db4e6;
        color: #fff;
      }
    }
  }
}

.service-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 20px 0;
  padding: 0 15px;
  .title {
    color: #f9a919;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
  }
  li {
    width: 100%;
    float: left;
    margin-bottom: 5px; 
    .ref {
      width: auto;
      float: left;
      color: #f9a919;
      font-weight: 600;
      padding-top: 10px;
      font-size: 16px;
    }
    .service-content {
      width: 100%;
      float: left;
      .partition {
        width: 50%;
        float: left;
        &:first-of-type {
          border-right: 1px solid #fff;
          padding-right: 15px;
        }
        &:last-of-type {
          padding-left: 15px;
        }

        .income-target-list {
          li {
            line-height: 1;
            &:first-child {
              margin-bottom: 10px;
            }
            span {
              color: #ffd700;
              font-weight: 600;
              font-size: 18px;
            }
            p {
              color: #3eb3e6;
              font-size: 11px;
              font-weight: 600;
              margin: 0;
            }
          }
        }

        .lft {
          width: 62%;
          float: left;
          span {
            font-size: 11px;
            font-weight: 600;
            &:first-of-type {
              color: #ffd700;
            }
            &:last-of-type {
              color: #3eb3e6;
            }
          }
          h3 {
            color: #ffd700;
            font-size: 44px;
            font-weight: 800;
            margin-bottom: 0;
            &.font-36 {
              font-size: 36px;
            }
            small {
              font-size: 15px;
              font-weight: 800;
              color: #3eb3e6;
            }
          }
        }
        .rht {
          width: 38%;
          float: left;
          

          // :root {
          //   --pgPercentage: 0;
          // }

          @keyframes growProgressBar {
            0%,
            33% {
              --pgPercentage: 0;
            }
            100% {
              --pgPercentage: var(--value);
            }
          }

          

          div[role="progressbar"] {
            --size: 6rem;
            --fg: #ffd700;
            --bg: #3eb3e6;
            --pgPercentage: var(--value);
            // margin-top: 20px;
            &.income {
              --size: 6.2rem; 
              // margin-top: 5px; 
              margin-left: 15px;
            }
            animation: growProgressBar 3s 1 forwards;
            width: var(--size);
            height: var(--size);
            border-radius: 50%;
            display: grid;
            place-items: center;
            background: radial-gradient(
                closest-side,
                #00579c 54%,
                transparent 0 99.9%,
                #00579c 0
              ),
              conic-gradient(
                var(--fg) calc(var(--pgPercentage) * 1%),
                var(--bg) 0
              );
            font-family: Helvetica, Arial, sans-serif;
            font-size: calc(var(--size) / 5);
            color: var(--fg);
          }

          div[role="progressbar"]::before {
            counter-reset: percentage var(--value);
            content: counter(percentage) "%";
            color: #ffd700;
            font-weight: 600;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.new-center-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 5px;
  // margin: 0;
  li {
    width: 25%;
    float: left;
    text-align: center;
    a {
      background-color: #ebebeb;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #3db4e6;
        color: #fff;
      }
    }
  }
}

.tab-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  background-color: #3db4e6;
  border-radius: 15px;
  li {
    cursor: pointer;
    width: 33.33333333%;
    float: left;
    text-align: center;
    background-color: #3db4e6;
    color: #fff;
    padding: 5px 5px 12px;
    border-radius: 20px 20px 0 0;
    font-weight: 600;
    &.active {
      background-color: #00579c;
      color: #fff;
    }
  }
}

.c-tab-content {
  margin-top: -10px;
  width: 100%;
  float: left;
  position: relative;
  border-radius: 15px;
  background-color: #00579c;
  padding-bottom: 25px;
  .c-tab-item {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0;
    padding: 8px 15px 15px;
    li {
      width: 33.33333333%;
      float: left;
      color: #fff;
      padding: 2px 10px 0 0;
      line-height: 1.2;
      // border-radius: 20px 20px 0 0;
      // font-weight: 600;

      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
      }

      &:nth-child(3) {
        padding-left: 3px;
        padding-right: 3px;
      }

      &:nth-child(2) {
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
      }

      .service-name {
        color: #f9aa1a;
        font-size: 15px;
        line-height: 1;
        font-weight: 600;
      }
      .target-text {
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin-top: 2px;
      }
      .pt-count {
        color: #f9aa1a;
        font-weight: 600;
        font-size: 32px;
      }
      .money-count {
        color: #f9aa1a;
        font-weight: 600;
        font-size: 18px;
        small {
          font-weight: 600;
        }
      }
      .pt-label {
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        margin-left: 6px;
      }
    }
  }

  .c-progressbar {
    width: 85%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    color: #fff;
    padding: 10px 15px 0;
    margin: 3px 0;

    li {
      float: left;
      height: 15px;
      position: relative;
      border-right: 2px solid #00579c;
      &:last-child {
        border-right: none;
      }
      span {
        position: absolute;
        left: 0;
        font-weight: 600;
        top: -17px;
        left: 0;
        font-size: 12px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.filter-list-new {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  li {
    width: 100px;
    float: right;
    text-align: center;
    button {
      color: #000;
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
.popover {
  max-width: 360px;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}
.progress_bar_bullet {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  p {
    margin-left: 10px;
    color: #fff;
    margin-bottom: 0;
  }
  span {
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
